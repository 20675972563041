// src/components/LoginForm.js

import React, { useState } from "react";
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";

const LoginForm = ({ onLogin, error, loading }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(username, password, rememberMe);
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-md brightness-50 z-[-2]"
        style={{
          backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')`,
        }}
      ></div>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-60 z-[-1]"></div>

      <div className="flex flex-col items-center justify-center min-h-screen px-4 py-8">
        <div className="bg-base-200 bg-opacity-90 rounded-xl shadow-2xl w-full max-w-md flex flex-col p-8 animate-fade-in-up">
          <h1 className="text-4xl font-extrabold text-center mb-4 text-primary">
            Willkommen zurück
          </h1>
          <p className="text-center text-gray-500 mb-6">
            Melde dich an, um fortzufahren.
          </p>

          {error && (
            <div className="alert alert-error mb-4 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 stroke-current mr-2"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>Fehler: {error.message || error}</span>
            </div>
          )}
          {loading && (
            <div className="flex items-center justify-center mb-4">
              <span className="loading loading-ring loading-lg"></span>
              <span className="ml-4 text-lg font-semibold text-primary">
                Login wird verarbeitet...
              </span>
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <input
                type="text"
                placeholder="Benutzername"
                className="input input-bordered w-full pl-12 pr-4 py-3 bg-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <FaUser className="w-5 h-5 text-gray-500 absolute left-4 top-1/2 transform -translate-y-1/2" />
            </div>

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Passwort"
                className="input input-bordered w-full pl-12 pr-12 py-3 bg-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FaLock className="w-5 h-5 text-gray-500 absolute left-4 top-1/2 transform -translate-y-1/2" />
              <button
                type="button"
                onClick={toggleShowPassword}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                aria-label={showPassword ? "Passwort verbergen" : "Passwort anzeigen"}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>

            <button
              type="submit"
              className={`btn btn-primary w-full ${loading ? "loading" : ""}`}
              disabled={loading}
            >
              Anmelden
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
