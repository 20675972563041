// src/components/Dashboard.js

import React, { useState, useEffect, useContext } from "react";
import { fetchDashboardData, logoutUser } from "../api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { getRankTextColor } from "../utils/rankColors";
import { formatRankName } from "../utils/rankNames";
import { Bar, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { FaDiscord, FaTwitter, FaInstagram, FaSearch } from "react-icons/fa";
import { API_BASE_URL } from "../api"; // Passen Sie den Pfad entsprechend Ihrer Struktur an

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    teamMembers: [],
    activeChatLogs: [],
    adminCount: 0,
    moderatorCount: 0,
    userCount: 0,
    totalChatLogs: 0,
    activeUsers: 0,
    timeInTeam: 0,
    createdAt: null,
    // Fügen Sie weitere Felder hinzu, falls nötig
  });

  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { logout, isLoggedIn, loading } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn) {
      loadDashboardData();
    }
  }, [navigate, isLoggedIn]);

  const loadDashboardData = async () => {
    try {
      const response = await fetchDashboardData();
      console.log("Dashboard response:", response); // Debugging

      const data = response.data; // Extrahieren Sie das 'data'-Objekt
      console.log("Extracted data:", data); // Debugging

      setDashboardData(data);

      // Überprüfen Sie den Passwort- und 2FA-Status
      if (data.passwordTemporary) {
        console.log("Navigating to /change-password"); // Debugging
        navigate("/change-password");
      } else if (!data.is2faEnabled) {
        console.log("Navigating to /setup-2fa"); // Debugging
        navigate("/setup-2fa");
      }
    } catch (err) {
      console.error("Error loading dashboard data:", err);
      setError(err.message);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      logout(); // Zustand im AuthContext aktualisieren
      navigate("/login");
    } catch (err) {
      console.error("Fehler beim Logout:", err.message || err);
      alert("Logout fehlgeschlagen. Bitte versuchen Sie es erneut.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
        <p className="text-xl animate-pulse">Lade Dashboard...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-red-500">
        <p className="text-xl">
          Fehler beim Laden der Dashboard-Daten: {error}
        </p>
      </div>
    );
  }

  if (!dashboardData || Object.keys(dashboardData).length === 0) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
        <p className="text-xl">
          Keine Daten verfügbar. Bitte versuchen Sie es später erneut.
        </p>
      </div>
    );
  }

  // Chart-Konfigurationen
  const barChartData = {
    labels: ["Januar", "Februar", "März", "April", "Mai"],
    datasets: [
      {
        label: "Aktive Chatlogs",
        data: dashboardData.activeChatLogs, // Beispiel: [12, 19, 3, 5, 2]
        backgroundColor: "rgba(59, 130, 246, 0.7)", // Blau
        borderColor: "rgba(59, 130, 246, 1)",
        borderWidth: 1,
      },
    ],
  };

  const pieChartData = {
    labels: ["Admin", "Moderator", "User"],
    datasets: [
      {
        data: [
          dashboardData.adminCount,
          dashboardData.moderatorCount,
          dashboardData.userCount,
        ],
        backgroundColor: ["#F97316", "#3B82F6", "#1F2937"], // sky, Blau, Dunkelgrau
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {/* Hintergrundbild mit Unschärfe und Verdunkelung */}
      <div
        className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-md brightness-50 z-[-2]"
        style={{
          backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')`,
        }}
      ></div>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-60 z-[-1]"></div>

      {/* Zentraler Container */}
      <div className="flex flex-col items-center justify-start min-h-screen pt-24 px-4 py-8">
        <div className="bg-base-200 bg-opacity-90 rounded-xl shadow-2xl w-full max-w-7xl flex flex-col h-full md:p-8 p-4 animate-fade-in-up">
          {/* Überschrift */}
          <h1 className="text-4xl md:text-5xl font-extrabold text-center mb-6 text-primary">
            Dashboard
          </h1>

          {/* Suchleiste und Aktionen */}
          <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6 space-y-4 md:space-y-0 md:space-x-6">
            {/* Suchleiste */}
            <div className="relative w-full md:w-2/3 lg:w-1/2">
              <input
                type="text"
                placeholder="Nach Statistiken oder Benutzern suchen..."
                className="input input-bordered w-full pl-12"
              />
              <FaSearch className="w-5 h-5 text-gray-500 absolute left-4 top-1/2 transform -translate-y-1/2" />
            </div>

            {/* Aktionen */}
            <div className="flex items-center space-x-4 w-full md:w-auto">
              <button
                onClick={() => navigate("/chatlogs")}
                className="btn btn-primary"
              >
                Chatlogs anzeigen
              </button>
              <button onClick={handleLogout} className="btn btn-error">
                Abmelden
              </button>
            </div>
          </div>

          {/* Hauptinhalt */}
          <div className="flex flex-col lg:flex-row flex-1 w-full space-y-6 lg:space-y-0 lg:space-x-6">
            {/* Zeit im Team */}
            <div className="stats shadow w-full lg:w-1/3">
              <div className="stat">
                <div className="stat-figure text-primary flex items-center justify-center h-8 w-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="h-full w-full stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m2 4v-4a2 2 0 10-4 0v4m5 0h2a2 2 0 002-2v-5a2 2 0 00-2-2h-2V7a2 2 0 00-2-2h-1a2 2 0 00-2 2v2H8a2 2 0 00-2 2v5a2 2 0 002 2h2"
                    ></path>
                  </svg>
                </div>
                <div className="stat-title text-gray-600">Zeit im Team</div>
                <div className="stat-value text-primary text-xl">
                  {dashboardData.timeInTeam || "Keine Daten"} Tage
                </div>
                <div className="stat-title text-gray-600 mt-4">Beigetreten</div>
                <div className="stat-value text-secondary text-xl">
                  {dashboardData.createdAt
                    ? new Date(dashboardData.createdAt).toLocaleDateString()
                    : "Keine Daten"}
                </div>
              </div>
            </div>

            {/* Weitere Statistiken */}
            <div className="stats shadow w-full lg:w-1/3">
              <div className="stat">
                <div className="stat-figure text-secondary flex items-center justify-center h-8 w-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="h-full w-full stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    ></path>
                  </svg>
                </div>
                <div className="stat-title text-gray-600">Total Chatlogs</div>
                <div className="stat-value text-secondary text-xl">
                  {dashboardData.totalChatLogs || "Keine Daten"}
                </div>
                <div className="stat-title text-gray-600 mt-4">
                  Aktive Benutzer
                </div>
                <div className="stat-value text-secondary text-xl">
                  {dashboardData.activeUsers || "Keine Daten"}
                </div>
              </div>
            </div>

            {/* Aktionen */}
            <div className="card bg-base-100 shadow-xl w-full lg:w-1/3">
              <div className="card-body">
                <h2 className="card-title">Aktionen</h2>
                <div className="card-actions justify-start mt-4">
                  <button
                    onClick={() => navigate("/chatlogs")}
                    className="btn btn-primary w-full"
                  >
                    Chatlogs anzeigen
                  </button>
                  <button
                    onClick={handleLogout}
                    className="btn btn-error w-full mt-2"
                  >
                    Abmelden
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Diagramme */}
          <section className="space-y-8 mt-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-primary">
              Übersicht
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="card bg-base-100 shadow-xl">
                <div className="card-body">
                  <h3 className="card-title">Aktive Chatlogs</h3>
                  <Bar
                    data={barChartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          labels: {
                            color: "#374151", // Dunkles Grau
                          },
                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            color: "#374151",
                          },
                          grid: {
                            color: "#D1D5DB", // Helles Grau
                          },
                        },
                        x: {
                          ticks: {
                            color: "#374151",
                          },
                          grid: {
                            color: "#D1D5DB",
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="card bg-base-100 shadow-xl">
                <div className="card-body">
                  <h3 className="card-title">Benutzerverteilung</h3>
                  <Pie
                    data={pieChartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          labels: {
                            color: "#374151",
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Teammitglieder */}
          <section className="mt-12">
            <h2 className="text-3xl font-bold text-center mb-12 text-primary">
              Dein Team
            </h2>
            {dashboardData?.teamMembers?.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {dashboardData.teamMembers.map((member, index) => (
                  <div
                    key={index}
                    className="card bg-base-100 shadow-xl hover:shadow-2xl transition-transform transform hover:scale-105"
                  >
                    <div className="card-body">
                      <div className="flex items-center mb-4">
                        <div className="avatar">
                          <div className="w-16 rounded-full">
                            <img
                              src={
                                member.avatar ||
                                `https://via.placeholder.com/150`
                              }
                              alt={member.username}
                            />
                          </div>
                        </div>
                        <div className="ml-4 flex-1">
                          <h4 className="text-lg font-semibold truncate">
                            {member.username || "Unbekannter Benutzer"}
                          </h4>
                          <p
                            className={`text-sm ${
                              getRankTextColor(member.rank) || "text-gray-500"
                            }`}
                          >
                            {formatRankName(member.rank) || "Keine Daten"}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center">
                        <p className="text-gray-700 mb-2 w-full md:w-auto">
                          <span className="font-bold">Im Team seit:</span>
                          <span className="ml-1 text-gray-700">
                            {member.timeInTeam
                              ? `${member.timeInTeam} ${
                                  member.timeInTeam === 1 ? "Tag" : "Tage"
                                }`
                              : "Keine Daten"}
                          </span>
                        </p>
                        <p className="text-gray-700 w-full md:w-auto">
                          <span className="font-bold">
                            Bearbeitete Reports:
                          </span>
                          <span className="ml-1 text-gray-700">
                            {member.reportsHandled || 0}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center text-gray-500">
                Keine Teammitglieder verfügbar.
              </p>
            )}
          </section>

          {/* Footer */}
          <footer className="bg-base-200 py-8 mt-16">
            <div className="max-w-7xl mx-auto text-center">
              <p className="text-gray-700 text-sm">
                &copy; {new Date().getFullYear()} Ravanity. Alle Rechte
                vorbehalten.
              </p>
              <div className="flex justify-center space-x-6 mt-4">
                <a
                  href="#"
                  className="text-primary hover:text-primary-focus transition"
                >
                  <FaDiscord className="text-2xl" />
                </a>
                <a
                  href="#"
                  className="text-primary hover:text-primary-focus transition"
                >
                  <FaTwitter className="text-2xl" />
                </a>
                <a
                  href="#"
                  className="text-secondary hover:text-secondary-focus transition"
                >
                  <FaInstagram className="text-2xl" />
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
