// src/components/ProtectedRoute.js

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { FaExclamationTriangle } from "react-icons/fa";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, loading, error } = useContext(AuthContext);

  console.log("ProtectedRoute:", { isLoggedIn, loading, error });

  if (loading) {
    return (
      <>
        {/* Hintergrundbild mit Verdunkelung */}
        <div
          className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-md brightness-50 z-[-2]"
          style={{
            backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')`,
          }}
        ></div>
        <div className="fixed top-0 left-0 w-full h-full bg-black opacity-60 z-[-1]"></div>

        {/* Lade-Container */}
        <div className="min-h-screen flex items-center justify-center px-4">
          <div className="bg-base-200 bg-opacity-90 rounded-xl shadow-2xl w-full max-w-md p-8">
            <div className="text-center">
              <span className="loading loading-ring loading-lg text-primary"></span>
              <p className="mt-4 text-lg font-semibold text-primary">
                Lade Authentifizierungsstatus...
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        {/* Hintergrundbild mit Verdunkelung */}
        <div
          className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-md brightness-50 z-[-2]"
          style={{
            backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')`,
          }}
        ></div>
        <div className="fixed top-0 left-0 w-full h-full bg-black opacity-60 z-[-1]"></div>

        {/* Error-Container */}
        <div className="min-h-screen flex items-center justify-center px-4">
          <div className="bg-base-200 bg-opacity-90 rounded-xl shadow-2xl w-full max-w-md p-8">
            <div className="text-center space-y-4">
              <div className="flex flex-col items-center">
                <FaExclamationTriangle className="text-red-500 text-5xl mb-2" />
                <h2 className="text-2xl font-bold text-primary">
                  Ein Fehler ist aufgetreten
                </h2>
              </div>
              <p className="text-gray-500">
                {error.message || "Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut."}
              </p>
              <button
                onClick={() => window.location.reload()}
                className="btn btn-primary w-full"
              >
                Neu laden
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!isLoggedIn) {
    console.log("ProtectedRoute: User not logged in. Redirecting to /login.");
    return <Navigate to="/login" replace />;
  }

  console.log("ProtectedRoute: User logged in. Rendering children.");
  return children;
};

export default ProtectedRoute;
