// src/App.js
import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { FolderIcon, XMarkIcon } from "@heroicons/react/24/outline";
import ChatLogView from "./components/ChatLogView";
import Navbar from "./components/Navbar";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Login from "./components/Login";
import longtext from './assets/Ravanity - Longtext.png';
import ChangePassword from "./components/ChangePassword";
import AuthContextRedirect from "./components/AuthContextRedirect";
import Setup2FA from "./components/Setup2FA";
import Dashboard from "./components/Dashboard";
import TeamSection from "./components/TeamSection";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import ChatLogsList from "./components/ChatLogsList";
import { ThemeProvider } from "./context/ThemeContext";
import Footer from "./components/Footer";
import {
  FaDiscord,
  FaTwitter,
  FaInstagram,
  FaChevronDown,
} from "react-icons/fa";

const HomePage = () => {
  const { isLoggedIn, user } = useContext(AuthContext);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const scrollToSection = () => {
    const section = document.getElementById("main-section");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="relative flex flex-col items-center justify-center min-h-screen">
        {/* Hintergrundbild mit Blur und Dunkelheit */}
        <div
          className="absolute inset-0 bg-cover bg-center filter blur-lg brightness-50 z-[-2]"
          style={{
            backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')`,
          }}
        ></div>

        {/* Übergang zum Basishintergrund */}
        <div className="absolute bottom-0 w-full h-48 bg-gradient-to-t from-bg-base-200 via-transparent to-transparent z-[-1]"></div>

        {/* Content */}
        <div className="relative z-10 text-center px-6">
          <img
            src={longtext}
            alt="Ravanity Logo"
            className="w-48 md:w-64 mb-6 mx-auto animate-fadeIn"
          />
          <h1
            className="text-4xl md:text-6xl font-extrabold mb-6 text-white"
            data-aos="fade-down"
          >
            Willkommen bei <span className="text-primary">Ravanity</span>
          </h1>
          <p
            className="text-lg md:text-xl text-gray-300 mb-8 max-w-2xl mx-auto"
            data-aos="fade-up"
          >
            Tauche ein in die besten Minecraft-Erlebnisse und werde Teil unserer
            einzigartigen Community.
          </p>

          {/* Buttons */}
          <div
            className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 justify-center"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            {isLoggedIn && user ? (
              <Link
                to="/dashboard"
                className="px-6 py-3 text-lg font-bold rounded-full bg-blue-500 text-white shadow-md hover:bg-blue-600 hover:shadow-lg transition-transform transform hover:scale-105"
              >
                Zum Dashboard
              </Link>
            ) : (
              <>
                <Link
                  to="/login"
                  className="px-6 py-3 text-lg font-bold rounded-full bg-green-500 text-white shadow-md hover:bg-green-600 hover:shadow-lg transition-transform transform hover:scale-105"
                >
                  Login
                </Link>
              </>
            )}
          </div>

          {/* Scroll Arrow */}
        </div>
      </div>

      {/* Hauptsektion */}
      <div id="main-section" className="bg-base-200">
        {/* Über Ravanity */}
        <section className="py-16">
          <div className="max-w-7xl mx-auto px-6">
            <h2
              className="text-4xl md:text-5xl font-extrabold text-center mb-12 text-primary"
              data-aos="fade-up"
            >
              Über Ravanity
            </h2>
            <p
              className="text-lg md:text-xl text-gray-700 leading-relaxed mb-12 text-center max-w-3xl mx-auto"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Willkommen bei{" "}
              <span className="font-bold text-primary">Ravanity</span>, einer
              einzigartigen Minecraft-Community, die Innovation und Kreativität
              kombiniert. Unser Ziel ist es, eine Umgebung zu schaffen, in der
              Spieler gemeinsam Spaß haben, lernen und wachsen können.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Karten */}
              <div
                className="card bg-base-100 shadow-xl hover:shadow-2xl transition-transform transform hover:scale-105"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="card-body text-center">
                  <div className="mx-auto mb-4">
                    <div className="w-16 h-16 rounded-full bg-primary flex items-center justify-center text-white text-3xl">
                      ✓
                    </div>
                  </div>
                  <h3 className="text-2xl font-semibold mb-2">Community</h3>
                  <p className="text-gray-600">
                    Eine engagierte Community mit Tausenden von Spielern.
                  </p>
                </div>
              </div>
              <div
                className="card bg-base-100 shadow-xl hover:shadow-2xl transition-transform transform hover:scale-105"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="card-body text-center">
                  <div className="mx-auto mb-4">
                    <div className="w-16 h-16 rounded-full bg-secondary flex items-center justify-center text-white text-3xl">
                      ✓
                    </div>
                  </div>
                  <h3 className="text-2xl font-semibold mb-2">Innovation</h3>
                  <p className="text-gray-600">
                    Einzigartige Server-Erlebnisse mit individuell gestalteten
                    Plugins.
                  </p>
                </div>
              </div>
              <div
                className="card bg-base-100 shadow-xl hover:shadow-2xl transition-transform transform hover:scale-105"
                data-aos="fade-up"
                data-aos-delay="800"
              >
                <div className="card-body text-center">
                  <div className="mx-auto mb-4">
                    <div className="w-16 h-16 rounded-full bg-accent flex items-center justify-center text-white text-3xl">
                      ✓
                    </div>
                  </div>
                  <h3 className="text-2xl font-semibold mb-2">Updates</h3>
                  <p className="text-gray-600">
                    Ständige Updates und neue Features, um das Gameplay spannend
                    zu halten.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Unsere Statistiken */}
        <section className="py-16 bg-base-200">
          <div className="max-w-7xl mx-auto px-6">
            <h2
              className="text-4xl md:text-5xl font-extrabold text-center mb-12 text-primary"
              data-aos="fade-up"
            >
              Unsere Statistiken
            </h2>
            <div
              className="stats stats-vertical lg:stats-horizontal shadow w-full"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="stat">
                <div className="stat-title">Benutzer</div>
                <div className="stat-value text-primary">1.200</div>
                <div className="stat-desc">Seit letztem Monat</div>
              </div>
              <div className="stat">
                <div className="stat-title">Ereignisse</div>
                <div className="stat-value text-secondary">50</div>
                <div className="stat-desc">In diesem Jahr</div>
              </div>
              <div className="stat">
                <div className="stat-title">Plugins</div>
                <div className="stat-value text-accent">30+</div>
                <div className="stat-desc">Aktive Plugins</div>
              </div>
              <div className="stat">
                <div className="stat-title">Support-Anfragen</div>
                <div className="stat-value text-warning">300</div>
                <div className="stat-desc">Erfolgreich gelöst</div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 bg-base-200">
          <div className="max-w-7xl mx-auto px-6">
            <h2
              className="text-4xl md:text-5xl font-extrabold text-center mb-12 text-primary"
              data-aos="fade-up"
            >
              Unser Team
            </h2>
            <TeamSection />
          </div>
        </section>

        {/* Kontakt */}
        <section className="py-16 bg-base-200">
          <div className="max-w-7xl mx-auto px-6 text-center">
            <h2
              className="text-4xl md:text-5xl font-extrabold mb-8 text-primary"
              data-aos="fade-up"
            >
              Kontakt
            </h2>
            <p
              className="text-lg text-gray-700 mb-12"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Hast du Fragen oder Anregungen? Kontaktiere uns über unsere
              Social-Media-Kanäle!
            </p>
            {/* Social Media Icons */}
            <div
              className="flex justify-center space-x-6"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <a
                href="#"
                className="text-primary hover:text-primary-focus transition"
              >
                <i className="fab fa-discord text-3xl"></i>
              </a>
              <a
                href="#"
                className="text-primary hover:text-primary-focus transition"
              >
                <i className="fab fa-twitter text-3xl"></i>
              </a>
              <a
                href="#"
                className="text-secondary hover:text-secondary-focus transition"
              >
                <i className="fab fa-instagram text-3xl"></i>
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const AppRoutes = () => {
  return (
    <>
      <Navbar />
      <Routes>
        {/* Root-Route, die basierend auf Authentifizierung weiterleitet */}
        <Route path="/" element={<HomePage />} />

        {/* Login-Route */}
        <Route path="/login" element={<Login />} />

        {/* Öffentliche Routen */}
        <Route path="/chatlog" element={<ChatLogView />} />
        <Route path="/chatlogs" element={<ChatLogsList />} />
        <Route path="/home" element={<HomePage />} />

        {/* Geschützte Routen */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/setup-2fa"
          element={
            <ProtectedRoute>
              <Setup2FA />
            </ProtectedRoute>
          }
        />
        {/* Weitere geschützte Routen können hier hinzugefügt werden */}
      </Routes>
      <Footer />
    </>
  );
};

const App = () => (
  <AuthProvider>
    <ThemeProvider>
      <Router>
        <AppRoutes />
      </Router>
    </ThemeProvider>
  </AuthProvider>
);

export default App;
