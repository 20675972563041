// src/components/Navbar.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import logo from '../assets/Ravanity - Logo.png';
import { logoutUser } from '../api';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, user, logout, loading } = useContext(AuthContext);
  const dropdownRef = useRef(null);

  // Überwache die Scroll-Position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) { // Schwellenwert für das Scrollen
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Initiale Überprüfung
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Schließe das mobile Menü und Dropdown-Menü bei Routenänderung
  useEffect(() => {
    setMobileMenuOpen(false);
    setDropdownOpen(false);
  }, [location.pathname]);

  // Klick außerhalb des Dropdowns zum Schließen
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Logout-Funktion
  const handleLogout = async () => {
    try {
      await logoutUser();
      logout();
      navigate('/login');
    } catch (err) {
      console.error('Fehler beim Logout:', err.message || err);
      alert('Logout fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  // UUID ohne Bindestriche für Avatar-URL
  const uuidWithoutDashes = user?.uuid?.replace(/-/g, '');

  if (loading) {
    return (
      <div className="fixed w-full z-50 bg-gray-800 shadow-md">
        <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
          <div className="text-2xl font-bold text-white">Ravanity</div>
          <div className="animate-pulse bg-gray-500 w-24 h-6 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <nav
      className={`fixed top-0 w-full z-50 transition-all duration-300 ${
        isScrolled
          ? 'bg-gray-900 bg-opacity-80 backdrop-blur-md shadow-lg'
          : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-6 py-4 flex items-center justify-between">
        {/* Logo und Titel */}
        <div className="flex items-center space-x-6">
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              alt="Ravanity Logo"
              className="w-10 h-10 mr-2"
            />
            <span className="text-xl font-bold text-white">Ravanity</span>
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="hidden lg:flex items-center space-x-8 ml-12"> {/* Mehr Abstand hinzugefügt */}
          <Link
            to="/"
            className={`${
              location.pathname === '/' ? 'font-semibold text-blue-400' : 'text-white'
            } hover:text-blue-400 transition duration-300`}
          >
            Home
          </Link>
          <Link
            to="/chatlog"
            className={`${
              location.pathname === '/chatlog' ? 'font-semibold text-blue-400' : 'text-white'
            } hover:text-blue-400 transition duration-300`}
          >
            Chatlogs
          </Link>
        </div>

        {/* Auth Buttons und Avatar */}
        <div className="flex items-center space-x-6 ml-auto">
          {isLoggedIn && user ? (
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="btn btn-ghost btn-circle avatar"
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
              >
                <div className="w-10 rounded-full border-2 border-blue-500">
                  <img
                    src={`https://crafatar.com/avatars/${uuidWithoutDashes}?size=40&overlay`}
                    alt={`${user.username} Avatar`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/images/default-avatar.png';
                    }}
                  />
                </div>
              </button>
              {dropdownOpen && (
                <ul
                  tabIndex={0}
                  className="menu menu-compact dropdown-content mt-2 p-2 shadow-lg bg-gray-800 rounded-box w-52 text-white absolute right-0 top-full"
                >
                  <li>
                    <Link to="/dashboard" onClick={() => setDropdownOpen(false)}>
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile" onClick={() => setDropdownOpen(false)}>
                      Profil
                    </Link>
                  </li>
                  <li>
                    <Link to="/settings" onClick={() => setDropdownOpen(false)}>
                      Einstellungen
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        handleLogout();
                        setDropdownOpen(false);
                      }}
                      className="w-full text-left hover:text-blue-400 transition duration-300"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              )}
            </div>
          ) : (
            <Link
              to="/login"
              className="btn btn-primary btn-outline rounded-full text-white border-white hover:bg-blue-600 transition duration-300"
            >
              Login
            </Link>
          )}

          {/* Mobile Menü Toggle */}
          <div className="lg:hidden">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="btn btn-ghost btn-circle"
              aria-label="Toggle mobile menu"
            >
              {mobileMenuOpen ? (
                <XMarkIcon className="w-6 h-6 text-white" />
              ) : (
                <Bars3Icon className="w-6 h-6 text-white" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menü */}
      {mobileMenuOpen && (
        <div className="lg:hidden bg-gray-900 bg-opacity-80 backdrop-filter backdrop-blur-sm shadow-md absolute top-full w-full z-40">
          <ul className="menu menu-compact px-6 py-4 space-y-4 text-white">
            <li>
              <Link
                to="/"
                className={`${
                  location.pathname === '/' ? 'font-semibold text-blue-400' : 'text-white'
                } hover:text-blue-400 transition duration-300`}
                onClick={() => setMobileMenuOpen(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/chatlog"
                className={`${
                  location.pathname === '/chatlog' ? 'font-semibold text-blue-400' : 'text-white'
                } hover:text-blue-400 transition duration-300`}
                onClick={() => setMobileMenuOpen(false)}
              >
                Chatlogs
              </Link>
            </li>
            {isLoggedIn && user && (
              <>
                <li className="border-t border-gray-600 my-2"></li>
                <li>
                  <Link
                    to="/dashboard"
                    className="text-white hover:text-blue-400 transition duration-300"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/profile"
                    className="text-white hover:text-blue-400 transition duration-300"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Profil
                  </Link>
                </li>
                <li>
                  <Link
                    to="/settings"
                    className="text-white hover:text-blue-400 transition duration-300"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Einstellungen
                  </Link>
                </li>
                <li>
                  <button
                    onClick={() => {
                      handleLogout();
                      setMobileMenuOpen(false);
                    }}
                    className="text-white hover:text-blue-400 transition duration-300 w-full text-left"
                  >
                    Logout
                  </button>
                </li>
              </>
            )}
            {!isLoggedIn && (
              <li>
                <Link
                  to="/login"
                  className="block px-4 py-2 bg-blue-500 text-white text-center rounded-md hover:bg-blue-600 transition duration-300"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Login
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
