import React, { useEffect, useState, useMemo, useRef } from "react";
import { fetchChatLogs, fetchUsernameByUUID } from "../api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaFilter, FaDownload } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";

const ChatMessageCard = ({ log, userMap, accusedUuid, highlightAccused }) => {
  const isAccused = log.chatLog.uuid === accusedUuid;

  // Falls Highlight aktiviert und es sich um den Beschuldigten handelt, roten Rand & Glow
  const accusedHighlight = highlightAccused && isAccused ? 
    "border-red-500 shadow-[0_0_8px_rgba(255,0,0,0.6)]" : "";

  // Dunkler Hintergrund, helle Schrift
  return (
    <div className={`card bg-gray-800 border border-gray-700 p-4 rounded-lg shadow-md transition-shadow ${accusedHighlight}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className="avatar">
            <div className="w-10 h-10 rounded-full border border-gray-600 overflow-hidden">
              <img
                src={`https://crafatar.com/avatars/${log.chatLog.uuid}?size=50&overlay`}
                alt={userMap[log.chatLog.uuid] || "Avatar"}
              />
            </div>
          </div>
          <div>
            <h3
              className={`text-md font-semibold ${
                highlightAccused && isAccused ? "text-red-300" : "text-gray-200"
              }`}
            >
              {userMap[log.chatLog.uuid] || "Unbekannt"}
            </h3>
          </div>
        </div>
        <p className="text-sm text-gray-400">
          {new Date(log.chatLog.timestamp).toLocaleString()}
        </p>
      </div>
      <div className="mt-2">
        <p className="text-gray-200 text-sm break-words">
          {log.chatLog.message}
        </p>
      </div>
    </div>
  );
};

const ChatLogView = () => {
  const [logs, setLogs] = useState([]);
  const [userMap, setUserMap] = useState({});
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [accusedUuid, setAccusedUuid] = useState(null);
  const [accusedName, setAccusedName] = useState(null);
  const [highlightAccused, setHighlightAccused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [keywordFilter, setKeywordFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const logsPerPage = 20;

  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionIdParam = urlParams.get("id");
    setSessionId(sessionIdParam);
    if (sessionIdParam) {
      fetchChatLogs(sessionIdParam)
        .then(async (data) => {
          if (!data || !Array.isArray(data.chatLogs)) {
            setError("Ungültige Daten erhalten.");
            setLoading(false);
            return;
          }
          setLogs(data.chatLogs || []);
          setAccusedUuid(data.accusedUuid);
          setAccusedName(data.accusedName);
          const uniqueUUIDs = [
            ...new Set(data.chatLogs.map((log) => log.chatLog.uuid)),
          ];
          const userMapTemp = {};

          const userPromises = uniqueUUIDs.map(async (uuid) => {
            try {
              const username = await fetchUsernameByUUID(uuid);
              userMapTemp[uuid] = username;
              return { uuid, name: username };
            } catch (err) {
              userMapTemp[uuid] = "Unbekannt";
              return { uuid, name: "Unbekannt" };
            }
          });

          await Promise.all(userPromises);
          setUserMap(userMapTemp);
          setLoading(false);
          scrollToBottom();
        })
        .catch((err) => {
          console.error(err);
          setError("Fehler beim Laden der Chatlogs");
          setLoading(false);
        });
    } else {
      setError("Keine Session-ID angegeben.");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem("chatlogFilters"));
    if (savedFilters) {
      setSelectedUsers(savedFilters.selectedUsers || []);
      setDateRange(savedFilters.dateRange || { start: "", end: "" });
      setKeywordFilter(savedFilters.keywordFilter || "");
      setHighlightAccused(savedFilters.highlightAccused || false);
    }
  }, []);

  useEffect(() => {
    const filters = {
      selectedUsers,
      dateRange,
      keywordFilter,
      highlightAccused,
    };
    localStorage.setItem("chatlogFilters", JSON.stringify(filters));
  }, [selectedUsers, dateRange, keywordFilter, highlightAccused]);

  useEffect(() => {
    if (!sessionId) return;

    const socket = new WebSocket("wss://ravanity.world:8082");

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.sessionId !== sessionId) return;

      if (message.event === "newMessage") {
        const newLog = JSON.parse(message.data);
        setLogs((prevLogs) => [...prevLogs, newLog]);
        // Neue Nachricht -> gehe ans Ende
        scrollToBottom();
      }
    };

    socket.onopen = () => console.log("WebSocket verbunden.");
    socket.onclose = () => console.log("WebSocket getrennt.");
    socket.onerror = (error) => console.error("WebSocket-Fehler:", error);

    return () => {
      socket.close();
    };
  }, [sessionId]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!loading) {
      scrollToBottom();
    }
  }, [loading]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleUserSelection = (uuid) => {
    setSelectedUsers((prev) =>
      prev.includes(uuid) ? prev.filter((id) => id !== uuid) : [...prev, uuid]
    );
    setCurrentPage(0);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
    setCurrentPage(0);
  };

  const handleKeywordChange = (e) => {
    setKeywordFilter(e.target.value);
    setCurrentPage(0);
  };

  const applyFilters = () => {
    setIsModalOpen(false);
    setCurrentPage(0);
  };

  const resetFilters = () => {
    setSelectedUsers([]);
    setDateRange({ start: "", end: "" });
    setKeywordFilter("");
    setHighlightAccused(false);
    setCurrentPage(0);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const searchTermLower = (searchTerm || "").toLowerCase();
  const keywordFilterLower = (keywordFilter || "").toLowerCase();

  const filteredLogs = useMemo(() => {
    return logs.filter((log) => {
      if (!log) return false;

      const messageText = (log.chatLog.message || "").toString().toLowerCase();
      const userName = (userMap[log.chatLog.uuid] || "").toString().toLowerCase();

      const messageMatch = messageText.includes(searchTermLower);
      const userMatch = userName.includes(searchTermLower);

      const userFilterMatch =
        selectedUsers.length === 0 || selectedUsers.includes(log.chatLog.uuid);

      const date = new Date(log.chatLog.timestamp);
      const startDate = dateRange.start ? new Date(dateRange.start) : null;
      const endDate = dateRange.end ? new Date(dateRange.end) : null;

      const dateFilterMatch =
        (!startDate || date >= startDate) && (!endDate || date <= endDate);

      const keywordMatch = keywordFilter
        ? messageText.includes(keywordFilterLower)
        : true;

      return (
        (messageMatch || userMatch) &&
        userFilterMatch &&
        dateFilterMatch &&
        keywordMatch
      );
    });
  }, [
    logs,
    userMap,
    searchTermLower,
    selectedUsers,
    dateRange.start,
    dateRange.end,
    keywordFilterLower,
  ]);

  const pageCount = Math.ceil(filteredLogs.length / logsPerPage);
  const displayedLogs = filteredLogs.slice(
    currentPage * logsPerPage,
    (currentPage + 1) * logsPerPage
  );

  const handleExportCSV = () => {
    const headers = [
      { label: "Nachricht", key: "message" },
      { label: "Benutzername", key: "username" },
      { label: "UUID", key: "uuid" },
      { label: "Zeitstempel", key: "timestamp" },
    ];
    const csvContent = [
      headers.map((header) => header.label).join(","),
      ...filteredLogs.map((log) =>
        [
          `"${log.chatLog.message.replace(/"/g, '""')}"`,
          `"${userMap[log.chatLog.uuid] || "Unbekannt"}"`,
          `"${log.chatLog.uuid}"`,
          `"${new Date(log.chatLog.timestamp).toLocaleString()}"`,
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `ChatLogs_${new Date().toISOString()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return (
      <ErrorPage message={error} onRetry={() => window.location.reload()} />
    );
  }

  return (
    <>
      {/* Hintergrundbild und Overlay */}
      <div
        className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-md brightness-50 z-[-2]"
        style={{
          backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')`,
        }}
      ></div>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-60 z-[-1]"></div>

      {/* Hauptinhalt */}
      <div className="flex flex-col items-center justify-start min-h-screen pt-24 px-4 py-8">
        <div className="bg-gray-900 bg-opacity-95 rounded-xl shadow-2xl w-full max-w-6xl flex flex-col h-full md:p-8 p-4 animate-fade-in-up">
          {/* Überschrift */}
          <h1 className="text-4xl md:text-5xl font-extrabold text-center mb-6 text-gray-100">
            Chatlog Ansicht
          </h1>

          {/* Such- und Filterleiste */}
          <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6 space-y-4 md:space-y-0 md:space-x-6">
            {/* Suchleiste */}
            <div className="relative w-full md:w-2/3 lg:w-1/2">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Nach Nachrichten oder Benutzern suchen..."
                className="input input-bordered w-full pl-12 bg-gray-800 text-gray-200 placeholder-gray-400 rounded-lg"
              />
              <FaSearch className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 transform -translate-y-1/2" />
            </div>

            {/* Export & Filter Buttons */}
            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto">
              <button
                onClick={handleExportCSV}
                className="btn btn-secondary btn-sm flex items-center bg-gray-700 text-gray-200 hover:bg-gray-600 border-gray-600 rounded-lg"
              >
                <FaDownload className="mr-2" /> Exportieren
              </button>
              <button
                onClick={() => setIsModalOpen(true)}
                className="btn btn-secondary btn-sm flex items-center bg-gray-700 text-gray-200 hover:bg-gray-600 border-gray-600 rounded-lg"
              >
                <FaFilter className="mr-2" /> Filter
              </button>
            </div>
          </div>

          {/* Chat-Nachrichten */}
          {filteredLogs.length > 0 ? (
            <div
              className="grid grid-cols-1 gap-3 overflow-y-auto pr-2"
              ref={chatContainerRef}
              style={{ maxHeight: "70vh" }}
            >
              {displayedLogs.map((log) => (
                <ChatMessageCard
                  key={log.chatLog.id || log.chatLog.uuid}
                  log={log}
                  userMap={userMap}
                  accusedUuid={accusedUuid}
                  highlightAccused={highlightAccused}
                />
              ))}
              <div ref={messagesEndRef} />
            </div>
          ) : (
            <div className="text-center text-gray-400">
              <p className="text-xl">Keine Nachrichten gefunden.</p>
            </div>
          )}

          {/* Paginierung */}
          {pageCount > 1 && (
            <div className="mt-6 flex justify-center">
              <ReactPaginate
                previousLabel={"← Zurück"}
                nextLabel={"Weiter →"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                forcePage={currentPage}
                containerClassName={"flex space-x-2"}
                pageClassName={"btn btn-outline btn-sm bg-gray-700 text-gray-200 border-gray-600 hover:bg-gray-600 rounded-lg"}
                pageLinkClassName={"focus:outline-none"}
                previousClassName={"btn btn-outline btn-sm bg-gray-700 text-gray-200 border-gray-600 hover:bg-gray-600 rounded-lg"}
                nextClassName={"btn btn-outline btn-sm bg-gray-700 text-gray-200 border-gray-600 hover:bg-gray-600 rounded-lg"}
                activeClassName={"btn-primary text-white"}
                disabledClassName={"btn-disabled text-gray-500"}
              />
            </div>
          )}
        </div>
      </div>

      {/* Modal für Filter */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-75"></div>
          <div className="bg-gray-800 rounded-lg shadow-lg z-10 p-8 w-11/12 max-w-md">
            <h2 className="text-2xl font-bold text-center mb-6 text-gray-100">
              Filter Optionen
            </h2>
            {/* Filter-Formulare */}
            <div className="space-y-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="highlightAccused"
                  checked={highlightAccused}
                  onChange={(e) => setHighlightAccused(e.target.checked)}
                  className="checkbox checkbox-primary"
                />
                <label
                  htmlFor="highlightAccused"
                  className="ml-2 text-gray-200"
                >
                  Beschuldigten hervorheben ({accusedName})
                </label>
              </div>
              {/* Filter nach Benutzern */}
              <div className="mb-4">
                <label className="block text-gray-200 mb-2">Benutzer filtern:</label>
                <div className="max-h-32 overflow-y-auto bg-gray-700 p-2 rounded">
                  {Array.from(new Set(logs.map((log) => log.chatLog.uuid))).map((uuid) => (
                    <div key={uuid} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={`user-${uuid}`}
                        checked={selectedUsers.includes(uuid)}
                        onChange={() => handleUserSelection(uuid)}
                        className="checkbox checkbox-primary"
                      />
                      <label
                        htmlFor={`user-${uuid}`}
                        className="ml-2 text-gray-200"
                      >
                        {userMap[uuid] || "Unbekannt"}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {/* Filter nach Datum */}
              <div className="mb-4">
                <label className="block text-gray-200 mb-2">Datum filtern:</label>
                <div className="flex space-x-2">
                  <input
                    type="date"
                    name="start"
                    value={dateRange.start}
                    onChange={handleDateChange}
                    className="input input-bordered w-full bg-gray-700 text-gray-200 placeholder-gray-400 border-gray-600"
                  />
                  <input
                    type="date"
                    name="end"
                    value={dateRange.end}
                    onChange={handleDateChange}
                    className="input input-bordered w-full bg-gray-700 text-gray-200 placeholder-gray-400 border-gray-600"
                  />
                </div>
              </div>
              {/* Filter nach Schlüsselwort */}
              <div className="mb-6">
                <label className="block text-gray-200 mb-2">Nach Schlüsselwort filtern:</label>
                <input
                  type="text"
                  value={keywordFilter}
                  onChange={handleKeywordChange}
                  placeholder="Schlüsselwort eingeben..."
                  className="input input-bordered w-full bg-gray-700 text-gray-200 placeholder-gray-400 border-gray-600"
                />
              </div>
            </div>
            {/* Buttons */}
            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={resetFilters}
                className="btn btn-outline text-gray-200 border-gray-500 hover:bg-gray-600"
              >
                Zurücksetzen
              </button>
              <button onClick={applyFilters} className="btn btn-primary">
                Anwenden
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ChatLogView;
