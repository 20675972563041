// src/components/TeamSection.js

import React, { useEffect, useState } from "react";
import { fetchTeamMembers } from "../api";
import { getRankTextColor, getBadgeBackgroundColor } from "../utils/rankColors";
import { formatRankName } from "../utils/rankNames";
import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const TeamSection = () => {
  const [team, setTeam] = useState([]);
  const [groupedTeam, setGroupedTeam] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    const loadTeam = async () => {
      try {
        const teamData = await fetchTeamMembers();

        // Berechne die Zeit im Team für jedes Mitglied
        const teamDataWithTime = teamData.map((member) => {
          const today = dayjs();
          const joined = dayjs(member.joinDate, "DD.MM.YYYY");
          if (!joined.isValid()) {
            // Ungültiges Datum behandeln
            member.timeYears = 0;
            member.timeMonths = 0;
            member.timeDays = 0;
          } else {
            const duration = getDuration(joined, today);
            member.timeYears = duration.years;
            member.timeMonths = duration.months;
            member.timeDays = duration.days;
          }
          return member;
        });

        setTeam(teamDataWithTime);
        setGroupedTeam(
          teamDataWithTime.reduce((acc, member) => {
            const rank = member.rank;
            acc[rank] = acc[rank] || [];
            acc[rank].push(member);
            return acc;
          }, {})
        );
      } catch {
        setError("Fehler beim Laden der Teamdaten.");
      } finally {
        setLoading(false);
      }
    };
    loadTeam();
  }, []);

  const rankOrder = [
    "admin",
    "management",
    "media_plus",
    "senior_developer",
    "developer",
    "senior_content",
    "content",
    "senior_builder",
    "builder",
    "senior_moderator",
    "moderator",
    "supporter",
  ];

  const pluralize = (count, singular, plural) =>
    count === 1 ? singular : plural;

  const getDisplayInfo = (gender, age) => {
    if (gender && gender !== "Keine Daten" && age && age !== "Keine Daten") {
      return `${gender}, ${age}`;
    }
    if (gender && gender !== "Keine Daten") {
      return gender;
    }
    if (age && age !== "Keine Daten") {
      return age;
    }
    return "";
  };

  const isNewMember = (joinDate) => {
    const today = dayjs();
    const joined = dayjs(joinDate, "DD.MM.YYYY");
    if (!joined.isValid()) return false;
    return today.diff(joined, "day") <= 2;
  };

  const isBirthday = (birthday) => {
    if (!birthday || birthday === "Keine Daten") return false;
    const today = dayjs();
    const birthDate = dayjs(birthday, "DD.MM.YYYY");
    if (!birthDate.isValid()) return false;
    return (
      today.date() === birthDate.date() && today.month() === birthDate.month()
    );
  };

  // Funktion zur Berechnung der genauen Dauer
  const getDuration = (startDate, endDate) => {
    let years = endDate.year() - startDate.year();
    let months = endDate.month() - startDate.month();
    let days = endDate.date() - startDate.date();

    if (days < 0) {
      months -= 1;
      days += startDate.daysInMonth();
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return { years, months, days };
  };

  return (
    <section className="py-16 bg-base-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {loading ? (
          <div className="space-y-6">
            <div className="h-8 w-1/3 bg-gray-100 rounded-md animate-pulse"></div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className="p-6 rounded-xl shadow-lg bg-gray-300 animate-pulse"
                >
                  <div className="h-24 w-full mb-4 bg-gray-400 rounded-md"></div>
                  <div className="h-6 w-2/3 bg-gray-400 rounded-md mb-2"></div>
                  <div className="h-4 w-1/2 bg-gray-400 rounded-md"></div>
                </div>
              ))}
            </div>
          </div>
        ) : error ? (
          <div className="text-center text-red-500 py-16 text-xl">{error}</div>
        ) : (
          <div className="space-y-12">
            {rankOrder.map((rank) => {
              const members = groupedTeam[rank];
              if (!members?.length) return null;
              const rankTextColor = getRankTextColor(rank);
              return (
                <div key={rank}>
                  <div className="flex items-center mb-6">
                    <h3
                      style={{ color: rankTextColor }}
                      className="text-2xl font-bold mr-2"
                    >
                      {formatRankName(rank)}
                    </h3>
                    <span className="text-white text-lg">
                      ({members.length})
                    </span>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {members.map((member) => {
                      const newMember = isNewMember(member.joinDate);
                      const birthdayToday = isBirthday(member.birthday);
                      const badgeBgColor = getBadgeBackgroundColor(
                        member.rank
                      );
                      return (
                        <div
                          key={member.uuid}
                          className="relative bg-base-100 bg-opacity-100 p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 flex flex-col sm:flex-row"
                          data-aos="fade-up"
                          data-aos-delay={`${Math.floor(
                            Math.random() * 200
                          )}`}
                        >
                          <div className="indicator relative flex-shrink-0">
                            {newMember && (
                              <span className="indicator-item badge badge-primary absolute top-0 right-0">
                                Neu im Team
                              </span>
                            )}
                            {birthdayToday && (
                              <span className="indicator-item badge badge-secondary absolute top-0 left-0">
                                Geburtstag
                              </span>
                            )}
                            <img
                              src={
                                member.avatar ||
                                "https://via.placeholder.com/150"
                              }
                              alt={`${member.name} Avatar`}
                              className="w-24 h-24 rounded-lg shadow-md object-cover"
                              loading="lazy"
                            />
                          </div>
                          <div className="mt-4 sm:mt-0 sm:ml-6 flex flex-col justify-center flex-1 text-center sm:text-left">
                            <div className="flex flex-col sm:flex-row sm:items-center mb-2">
                              <span
                                style={{ backgroundColor: badgeBgColor }}
                                className="px-3 py-1 text-white rounded-md text-xs font-semibold mr-0 sm:mr-2 mb-2 sm:mb-0 truncate"
                              >
                                {formatRankName(member.rank)}
                              </span>
                              <h4 className="text-lg font-semibold text-white truncate">
                                {member.name}
                              </h4>
                            </div>
                            {getDisplayInfo(member.gender, member.age) && (
                              <div className="text-gray-400 text-sm mb-2">
                                {getDisplayInfo(member.gender, member.age)}
                              </div>
                            )}
                            <span className="text-gray-300 text-sm">
                              Beitritt: {member.joinDate}
                            </span>
                            <div className="mt-4 grid grid-cols-3 gap-2">
                              {["Years", "Months", "Days"].map((unit, i) => (
                                <div key={i} className="text-center">
                                  <div className="text-lg font-bold text-white">
                                    {member[`time${unit}`]}
                                  </div>
                                  <div className="text-xs text-gray-400">
                                    {pluralize(
                                      member[`time${unit}`],
                                      unit.slice(0, -1),
                                      unit
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default TeamSection;
