// src/utils/rankColors.js

export const getRankTextColor = (rank) => {
  if (!rank) return "#A0AEC0"; // Standardfarbe für unbekannte Ränge (grau)
  switch (rank.toLowerCase()) {
    case "admin":
      return "#570000"; // rot-800
    case "management":
      return "#ff0000"; // rot-500
    case "media_plus":
      return "#460075"; // purple-800
    case "senior_developer":
    case "developer":
      return "#6efff3"; // cyan-400
    case "senior_content":
    case "content":
      return "#007e73"; // emerald-700
    case "senior_builder":
    case "builder":
      return "#166d00"; // green-700
    case "senior_moderator":
    case "moderator":
      return "#ff6f6f"; // orange-500
    case "supporter":
      return "#bbfda9"; // yellow-500
    default:
      return "#A0AEC0"; // Standardfarbe für unbekannte Ränge
  }
};

export const getBadgeBackgroundColor = (rank) => {
  if (!rank) return "#A0AEC0"; // Standardfarbe für unbekannte Ränge (grau)
  switch (rank.toLowerCase()) {
    case "admin":
      return "#570000"; // rot-800
    case "management":
      return "#ff0000"; // rot-500
    case "media_plus":
      return "#460075"; // purple-800
    case "senior_developer":
    case "developer":
      return "#6efff3"; // cyan-400
    case "senior_content":
    case "content":
      return "#007e73"; // emerald-700
    case "senior_builder":
    case "builder":
      return "#166d00"; // green-700
    case "senior_moderator":
    case "moderator":
      return "#ff6f6f"; // orange-500
    case "supporter":
      return "#bbfda9"; // yellow-500
    default:
      return "#A0AEC0"; // Standardfarbe für unbekannte Ränge
  }
};
