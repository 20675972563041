import React from 'react';

const LoadingPage = () => {
    return (
        <>
            {/* Hintergrundbild mit Unschärfe und Verdunkelung */}
            <div
                className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-sm brightness-50 z-[-2]"
                style={{ backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')` }}
            ></div>
            <div className="fixed top-0 left-0 w-full h-full bg-black opacity-40 z-[-1]"></div>

            {/* Zentraler Container */}
            <div className="flex flex-col items-center justify-center min-h-screen px-4 py-8">
                {/* Gläserner Loader-Container */}
                <div className="bg-gray-900 bg-opacity-80 backdrop-blur-lg rounded-lg shadow-lg p-8 text-center max-w-sm w-full">
                    {/* Spinner */}
                    <div className="flex items-center justify-center mb-6">
                        <div className="relative w-24 h-24">
                            <div className="absolute inset-0 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                        </div>
                    </div>

                    {/* Haupttext */}
                    <h2 className="text-xl font-bold text-white">Daten werden geladen...</h2>

                    {/* Zusätzlicher Text */}
                    <p className="text-sm text-gray-400 mt-2">Bitte warte einen Moment</p>
                </div>
            </div>
        </>
    );
};

export default LoadingPage;
