// src/components/ChangePassword.js

import React, { useState, useEffect } from "react";
import { changePassword } from "../api";
import PropTypes from 'prop-types'; // Optional für PropTypes
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Für visuelles Feedback

const ChangePassword = ({ onPasswordChanged }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordColor, setPasswordColor] = useState("progress-error");
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    evaluatePasswordStrength(newPassword, confirmPassword);
  }, [newPassword, confirmPassword]);

  const evaluatePasswordStrength = (password, confirmPassword) => {
    let strength = 0;
    let color = "progress-error";

    const requirements = [
      { regex: /.{8,}/, label: "Mindestens 8 Zeichen" },
      { regex: /[A-Z]/, label: "Mindestens ein Großbuchstabe" },
      { regex: /[a-z]/, label: "Mindestens ein Kleinbuchstabe" },
      { regex: /[0-9]/, label: "Mindestens eine Zahl" },
      { regex: /[^A-Za-z0-9]/, label: "Mindestens ein Sonderzeichen" },
    ];

    requirements.forEach((req) => {
      if (req.regex.test(password)) {
        strength += 20;
      }
    });

    if (strength <= 40) {
      color = "progress-error";
    } else if (strength <= 80) {
      color = "progress-warning";
    } else {
      color = "progress-success";
    }

    setPasswordStrength(strength);
    setPasswordColor(color);

    // Setze canSubmit nur, wenn die Stärke 100 erreicht und die Passwörter übereinstimmen
    setCanSubmit(strength === 100 && password === confirmPassword);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError(null);

    console.log("Passwort ändern angeklickt");
    if (newPassword !== confirmPassword) {
      setError("Passwörter stimmen nicht überein");
      console.log("Fehler: Passwörter stimmen nicht überein");
      return;
    }

    if (passwordStrength < 100) {
      setError("Passwort ist nicht sicher genug");
      console.log("Fehler: Passwort ist nicht sicher genug");
      return;
    }

    try {
      console.log("Sende Anfrage...");
      await changePassword(newPassword, confirmPassword);
      onPasswordChanged(); // Funktion aufrufen
      console.log("Passwort erfolgreich geändert");
    } catch (err) {
      setError(err.message || "Fehler beim Ändern des Passworts");
      console.error("Fehler:", err);
    }
  };

  return (
    <>
      {/* Hintergrundbild mit Verdunkelung */}
      <div
        className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-md brightness-50 z-[-2]"
        style={{
          backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')`,
        }}
      ></div>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-60 z-[-1]"></div>

      {/* Change Password-Container */}
      <div className="min-h-screen flex items-center justify-center px-4">
        <div className="bg-base-200 bg-opacity-90 rounded-xl shadow-2xl w-full max-w-md p-8">
          <div className="text-center mb-6">
            <h2 className="text-2xl font-bold text-primary mb-2">
              Passwort ändern
            </h2>
            <p className="text-gray-500">
              Bitte gib dein neues Passwort ein
            </p>
          </div>
          {error && (
            <div className="alert alert-error shadow-lg mb-4">
              <div>
                <FaTimesCircle className="text-red-500 mr-2" />
                <span>{error}</span>
              </div>
            </div>
          )}
          <form onSubmit={handleChangePassword} className="space-y-4">
            <div>
              <label
                htmlFor="newPassword"
                className="block text-gray-300 font-medium mb-2"
              >
                Neues Passwort:
              </label>
              <input
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-4 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Gib ein neues Passwort ein"
                required
              />
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-gray-300 font-medium mb-2"
              >
                Passwort bestätigen:
              </label>
              <input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Bestätige dein Passwort"
                required
              />
            </div>

            {/* Passwortstärkepanze */}
            <div className="flex flex-col items-start">
              <label className="block text-gray-300 font-medium mb-2">
                Passwortstärke:
              </label>
              <progress
                className={`progress ${passwordColor} w-full`}
                value={passwordStrength}
                max="100"
              ></progress>
              <p className="text-gray-400 text-sm mt-1">
                {passwordStrength <= 40 && "Garnicht sicher"}
                {passwordStrength > 40 && passwordStrength <= 80 && "Nicht ganz sicher"}
                {passwordStrength > 80 && "Sehr sicher"}
              </p>
            </div>

            {/* Anforderungen anzeigen */}
            <div className="space-y-1">
              <p className="text-gray-300 font-medium mb-2">Anforderungen:</p>
              <ul className="list-none pl-0 space-y-1">
                <li className="flex items-center text-gray-400 text-sm">
                  {newPassword.length >= 8 ? (
                    <FaCheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <FaTimesCircle className="text-red-500 mr-2" />
                  )}
                  Mindestens 8 Zeichen
                </li>
                <li className="flex items-center text-gray-400 text-sm">
                  {/[A-Z]/.test(newPassword) ? (
                    <FaCheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <FaTimesCircle className="text-red-500 mr-2" />
                  )}
                  Mindestens ein Großbuchstabe
                </li>
                <li className="flex items-center text-gray-400 text-sm">
                  {/[a-z]/.test(newPassword) ? (
                    <FaCheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <FaTimesCircle className="text-red-500 mr-2" />
                  )}
                  Mindestens ein Kleinbuchstabe
                </li>
                <li className="flex items-center text-gray-400 text-sm">
                  {/[0-9]/.test(newPassword) ? (
                    <FaCheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <FaTimesCircle className="text-red-500 mr-2" />
                  )}
                  Mindestens eine Zahl
                </li>
                <li className="flex items-center text-gray-400 text-sm">
                  {/[^A-Za-z0-9]/.test(newPassword) ? (
                    <FaCheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <FaTimesCircle className="text-red-500 mr-2" />
                  )}
                  Mindestens ein Sonderzeichen
                </li>
              </ul>
            </div>

            <button
              type="submit"
              className={`btn btn-primary w-full ${!canSubmit ? "opacity-50 cursor-not-allowed" : ""}`}
              disabled={!canSubmit}
            >
              Passwort ändern
            </button>
          </form>
          <div className="mt-4 text

center">
            <p className="text-gray-400 text-sm">
              Falls du Probleme hast,{" "}
              <a
                href="/support"
                className="text-blue-400 hover:underline transition duration-300"
              >
                kontaktiere den Support
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

ChangePassword.propTypes = {
  onPasswordChanged: PropTypes.func.isRequired,
};

export default ChangePassword;
