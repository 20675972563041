// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from "react";
import { fetchDashboardData as apiFetchDashboardData } from "../api"; // Importiere die Funktion korrekt

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [is2faEnabled, setIs2faEnabled] = useState(false);
  const [user, setUser] = useState(null);
  const [passwordTemporary, setPasswordTemporary] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Prüfe die Authentifizierung beim ersten Laden oder wenn sich die Abhängigkeiten ändern
  useEffect(() => {
    const checkAuth = async () => {
      try {
        setLoading(true);
        setError(null);
  
        const responseData = await apiFetchDashboardData();
        console.log('AuthProvider: Fetched dashboard data:', responseData);
  
        if (responseData.status === 'SUCCESS') {
          const data = responseData.data;
          setIsLoggedIn(true);
          setIs2faEnabled(data.is2faEnabled || false);
          setUser({ uuid: data.uuid, username: data.username });
          setPasswordTemporary(data.passwordTemporary || false);
        } else {
          throw new Error(responseData.message || 'Authentifizierungsfehler');
        }
      } catch (err) {
        console.error('AuthProvider: Authentication check failed:', err);
        setError(err);
        setIsLoggedIn(false);
        setIs2faEnabled(false);
        setPasswordTemporary(false);
        setUser(null);
        document.cookie = "authToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; SameSite=None;";
      } finally {
        setLoading(false);
      }
    };
  
    checkAuth();
  }, []);
  
  
  const login = () => {
    setIsLoggedIn(true);

  };

  const logout = async () => {
    try {
      await logoutUser(); // Call the logout API
      setIsLoggedIn(false);
      setIs2faEnabled(false);
      setPasswordTemporary(false);
      setUser(null);
      // Entferne das authToken-Cookie
      document.cookie = "authToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; SameSite=None;";
    } catch (error) {
      console.error("Fehler beim Logout:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        is2faEnabled,
        passwordTemporary,
        login,
        logout,
        loading,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
