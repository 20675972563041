// src/components/Setup2FA.js

import React, { useState, useEffect } from 'react';
import { setup2FA, verify2FACode } from '../api';

const Setup2FA = ({ on2FASetupComplete }) => {
    const [otpAuthURL, setOtpAuthURL] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOtpAuthURL = async () => {
            try {
                const data = await setup2FA();
                setOtpAuthURL(data.otpAuthURL);
            } catch (err) {
                if (err.message === "2FA is already set up") {
                    setError("Die Zwei-Faktor-Authentifizierung ist bereits aktiviert.");
                } else {
                    setError(err.message);
                }
            }
        };
    
        fetchOtpAuthURL();
    }, []);
    

    const handleVerifyCode = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            await verify2FACode(verificationCode);
            on2FASetupComplete();
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold text-center text-white mb-6">
                    2-Faktor-Authentifizierung einrichten
                </h2>
                {error && (
                    <div className="bg-red-600 text-white text-sm p-2 rounded mb-4">
                        {error}
                    </div>
                )}
                {otpAuthURL ? (
                    <>
                        <p className="text-gray-300 text-sm text-center mb-4">
                            Scanne den QR-Code mit deiner Authenticator-App.
                        </p>
                        <div className="flex justify-center mb-6">
                            <img
                                src={otpAuthURL}
                                alt="QR Code"
                                className="border-4 border-gray-700 rounded"
                            />
                        </div>
                        <form onSubmit={handleVerifyCode} className="space-y-4">
                            <div>
                                <label
                                    htmlFor="verificationCode"
                                    className="block text-gray-300 font-medium mb-2"
                                >
                                    Verifizierungscode:
                                </label>
                                <input
                                    id="verificationCode"
                                    type="text"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    className="w-full px-4 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Gib den Code ein"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300 font-semibold"
                            >
                                Code verifizieren
                            </button>
                        </form>
                    </>
                ) : (
                    <p className="text-gray-300 text-center">Lade QR-Code...</p>
                )}
            </div>
        </div>
    );
};

export default Setup2FA;
