// src/api/index.js

import axios from 'axios';

export const API_BASE_URL = "https://ravanity.world";
const API_KEY = "axKyHGzZSXP5G2XtR02xlXnZnIS4O56A"; // Ihr API-Key

export const fetchTeamMembers = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/team`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": API_KEY, // API-Key hinzufügen
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Fehler beim Abrufen der Teamdaten");
        }

        const responseData = await response.json();
        console.log("Teamdaten erfolgreich abgerufen:", responseData);

        // Extrahiere das 'data'-Feld, falls vorhanden
        const teamData = responseData.data || [];
        return teamData; // Rückgabe der Teamdaten
    } catch (error) {
        console.error("Fehler beim Abrufen der Teammitglieder:", error);
        throw error;
    }
};
// Fetch ChatLogs
export const fetchChatLogs = async (sessionId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/chatlog/${sessionId}`, {
            method: "GET",
            headers: {
                "X-API-KEY": API_KEY,
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to fetch chat logs");
        }
        const responseData = await response.json(); // Vollständige Antwort parsen
        const data = responseData.data; // Extrahiere das 'data'-Feld
        console.log("fetchChatLogs response data:", data); // Debugging

        if (!Array.isArray(data.chatLogs)) {
            console.error("Invalid chatLogs data:", data.chatLogs);
            return { chatLogs: [], accusedUuid: null, accusedName: null };
        }

        return data; // Rückgabe des gesamten 'data'-Objekts
    } catch (error) {
        console.error("Error fetching chat logs:", error);
        throw error;
    }
};



const fetchAndDisplayChatLogs = async (sessionId) => {
    try {
        const data = await fetchChatLogs(sessionId);
        const { chatLogs, accusedUuid, accusedName } = data; // Destrukturiere 'data'
        console.log("Accused Name:", accusedName);
        console.log("Accused UUID:", accusedUuid);
        console.log("Chat Logs:", chatLogs);
        // Verwende diese Daten, um sie im UI anzuzeigen
    } catch (error) {
        console.error("Error displaying chat logs:", error);
    }
};







// Fetch Username by UUID
export const fetchUsernameByUUID = async (uuid) => {
    try {
        console.log("Fetching username for UUID:", uuid); // Debugging
        const response = await fetch(`${API_BASE_URL}/api/user/${uuid}`, {
            method: "GET",
            headers: {
                "X-API-KEY": API_KEY,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("API Error Response:", errorData);
            throw new Error(errorData.message || "Failed to fetch username");
        }

        const data = await response.json();
        console.log("API Response for UUID:", uuid, data);

        // Rückgabe des Benutzernamens aus data.message
        return data.message; // Hier wird der Benutzername zurückgegeben
    } catch (error) {
        console.error("Error fetching username:", error);
        throw error;
    }
};

// Funktion zum Hinzufügen eines Benutzers zur Chatlog-Session
export const addUserToSession = async (sessionId, username) => {
    try {
        console.log("API_KEY:", API_KEY); // Debugging
        console.log("Request Body:", { username }); // Debugging

        const response = await fetch(`${API_BASE_URL}/api/chatlog/${sessionId}/addUser`, {
            method: "POST",
            headers: {
                "X-API-KEY": API_KEY,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username }),
            credentials: 'include', // Cookies mit senden
        });

        console.log("Response Status:", response.status); // Debugging HTTP-Status

        // Die Antwort nur einmal lesen
        const data = await response.json();

        if (!response.ok) {
            console.error("Error Response Data:", data); // Debugging Fehlerdaten
            throw new Error(data.message || "Failed to add user to session");
        }

        console.log("Successful API Response:", data); // Debugging Erfolg

        // Erfolgreiche Antwort zurückgeben
        return data;
    } catch (error) {
        console.error("Error in addUserToSession:", error);
        throw error;
    }
};










// Funktion zum Entfernen eines Benutzers aus der Chatlog-Session
export const removeUserFromSession = async (sessionId, uuid) => {
    try {
        console.log("Sending API Key:", API_KEY); // Debugging
        console.log("Removing UUID:", uuid); // Debugging

        const response = await fetch(`${API_BASE_URL}/api/chatlog/${sessionId}/removeUser/${uuid}`, {
            method: "DELETE",
            headers: {
                "X-API-KEY": API_KEY,
                "Content-Type": "application/json",
            },
            credentials: 'include', // Cookies mit senden
        });

        // Überprüfen, ob die Antwort erfolgreich war
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to remove user from session");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error removing user from session:", error);
        throw error;
    }
};


// Benutzer-Login
// Beispiel aus api.js
export const loginUser = async (username, password) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => null); // JSON parsen oder null, wenn ungültig
            const message = errorData?.message || "Serverfehler: Ungültige Antwort";
            throw new Error(message);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Fehler beim Login:", error.message);
        throw error;
    }
};



export const changePassword = async (newPassword, confirmPassword) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/change-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": API_KEY,
            },
            body: JSON.stringify({ newPassword, confirmPassword }),
            credentials: 'include', // Wichtig für das Senden von Cookies
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || "Passwortänderung fehlgeschlagen");
        }

        return data;
    } catch (error) {
        console.error("Fehler beim Ändern des Passworts:", error);
        throw error;
    }
};

// 2FA einrichten (QR-Code URL abrufen)
export const setup2FA = async () => {
    try {
        console.log('Fetching /api/setup-2fa...');
        const response = await fetch(`${API_BASE_URL}/api/setup-2fa`, {
            method: 'GET',
            credentials: 'include', // Authentifizierungs-Cookies werden gesendet
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log('Response Status:', response.status);
        if (!response.ok) {
            const errorData = await response.json().catch(() => null);
            console.error('Error Response Data:', errorData);
            throw new Error(errorData?.message || 'Fehler beim Starten der 2FA-Einrichtung');
        }

        const data = await response.json();
        console.log('Setup2FA Response:', data);
        return data.data; // Enthält otpAuthURL
    } catch (error) {
        console.error('Fehler bei der 2FA-Einrichtung:', error.message);
        throw error;
    }
};




// 2FA-Code verifizieren
export const verify2FACode = async (verificationCode) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/setup-2fa`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": API_KEY,
            },
            body: JSON.stringify({ verificationCode }),
            credentials: 'include',
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || "2FA-Verifizierung fehlgeschlagen");
        }

        return data;
    } catch (error) {
        console.error("Fehler bei der 2FA-Verifizierung:", error);
        throw error;
    }
};

export const fetchAllChatSessions = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/chatlogs/getallsessions`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": API_KEY,
            },
        });

        if (!response.ok) {
            // Versuche, die Fehlermeldung aus der Antwort zu extrahieren
            try {
                const errorData = await response.json();
                throw new Error(errorData.message || "Serverfehler beim Abrufen der Sessions");
            } catch {
                throw new Error("Unbekannter Fehler vom Server");
            }
        }

        const data = await response.json();
        return data.data || [];
    } catch (error) {
        console.error("Fehler beim Abrufen der Chatlog-Sessions:", error.message);
        throw error;
    }
};



// Dashboard-Daten abrufen

// src/api/index.js

export const fetchDashboardData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dashboard`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const responseData = await response.json();
  
      if (!response.ok || responseData.status !== 'SUCCESS') {
        throw new Error(responseData.message || 'Authentifizierungsfehler');
      }
  
      return responseData; // Geben Sie die gesamte Antwort zurück
    } catch (error) {
      console.error('Fehler beim Abrufen der Dashboard-Daten:', error.message);
      throw error;
    }
  };
  
  



  

// Benutzer ausloggen
export const logoutUser = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/logout`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY, // API-Key hinzufügen
            },
            credentials: 'include', // Cookies für Sitzungsverwaltung einschließen
            // body: JSON.stringify({}), // Optional: Leerer Body, falls vom Server erwartet
        });

        if (!response.ok) {
            // Versuche, die Fehlermeldung aus der Antwort zu extrahieren
            let errorMessage = 'Logout fehlgeschlagen';
            try {
                const errorData = await response.json();
                errorMessage = errorData.message || errorMessage;
            } catch (jsonError) {
                // Falls die Antwort kein gültiges JSON ist, behalte die Standardmeldung bei
            }
            throw new Error(errorMessage);
        }

        return await response.json();
    } catch (error) {
        console.error('Fehler beim Logout:', error);
        throw error;
    }
};


