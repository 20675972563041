// src/utils/rankNames.js

export const formatRankName = (rank) => {
    const rankMapping = {
      admin: "Administrator",
      management: "Manager",
      media_plus: "Media Plus",
      senior_developer: "Senior Developer",
      developer: "Developer",
      senior_content: "Senior Content",
      content: "Content",
      senior_builder: "Senior Builder",
      builder: "Builder",
      senior_moderator: "Senior Moderator",
      moderator: "Moderator",
      supporter: "Supporter",
      // Weitere Ränge und ihre formatierten Namen hinzufügen
    };
    return rankMapping[rank.toLowerCase()] || capitalizeFirstLetter(rank);
  };
  
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  