// src/components/Footer.js

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";

const Footer = () => {
  const location = useLocation();
  const { theme } = useContext(ThemeContext);

  const getFooterStyle = () => {
    if (location.pathname.includes("dashboard")) {
      return "bg-black bg-opacity-20 backdrop-blur-md";
    }
    if (location.pathname.includes("login")) {
      return "bg-black bg-opacity-20 backdrop-blur-md";
    }
    if (theme === "dark") {
      return "bg-black bg-opacity-20 backdrop-blur-md";
    }
    return "bg-gray-900 bg-opacity-60 backdrop-blur-md";
  };

  return (
    <footer
      className={`${getFooterStyle()} text-gray-300 py-16 relative overflow-hidden`}
    >
      <div className="max-w-7xl mx-auto px-6 relative z-10">
        {/* Rechtliche Links */}
        <ul className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8 text-sm">
          <li>
            <a href="#" className="hover:text-gray-100 transition transform hover:scale-105">
              Datenschutz
            </a>
          </li>
          <li>
            <a href="#" className="hover:text-gray-100 transition transform hover:scale-105">
              Nutzungsbedingungen
            </a>
          </li>
          <li>
            <a href="#" className="hover:text-gray-100 transition transform hover:scale-105">
              Impressum
            </a>
          </li>
        </ul>

        {/* Copyright */}
        <p className="text-center text-sm mt-6">
          &copy; {new Date().getFullYear()} Ravanity Network. Alle Rechte
          vorbehalten.
        </p>
      </div>

      {/* Glow-Effekte */}
      <div className="absolute top-5 left-5 h-32 w-32 rounded-full bg-gradient-to-br from-blue-500 via-blue-400 to-blue-600 blur-3xl opacity-15 animate-pulse"></div>
      <div className="absolute bottom-10 right-10 h-24 w-24 rounded-full bg-gradient-to-br from-purple-500 via-purple-400 to-purple-600 blur-2xl opacity-10 animate-pulse"></div>
    </footer>
  );
};

export default Footer;
