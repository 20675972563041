// src/components/ChatLogsList.js

import React, { useEffect, useState, useMemo } from "react";
import { fetchAllChatSessions, fetchUsernameByUUID } from "../api";
import { useNavigate } from "react-router-dom";
import {
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
  FaDownload,
} from "react-icons/fa";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";

const resolveUsernames = async (sessions) => {
  const updatedSessions = await Promise.all(
    sessions.map(async (session) => {
      if (!session.accusedName) {
        try {
          const accusedName = await fetchUsernameByUUID(session.accusedUuid);
          return { ...session, accusedName };
        } catch {
          return { ...session, accusedName: "Unbekannt" };
        }
      }
      return session;
    })
  );
  return updatedSessions;
};

const ChatCard = ({ session, onClick }) => (
  <div className="card bg-base-100 shadow-xl hover:shadow-2xl transition-transform transform hover:scale-105">
    <div className="card-body">
      <div className="flex items-center space-x-4">
        <div className="avatar">
          <div className="w-16 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
            <img
              src={`https://crafatar.com/avatars/${session.accusedUuid}?size=50&overlay`}
              alt={session.accusedName || "Avatar"}
            />
          </div>
        </div>
        <div>
          <h3 className="text-xl font-semibold text-primary">
            {session.accusedName || "Unbekannt"}
          </h3>
          <p className="text-sm text-gray-500">{session.accusedUuid}</p>
        </div>
      </div>
      <div className="mt-4 text-gray-700">
        <p>
          <span className="font-semibold">Session ID:</span> {session.sessionId}
        </p>
        <p>
          <span className="font-semibold">Erstellt am:</span>{" "}
          {new Date(session.createdAt).toLocaleString()}
        </p>
      </div>
      <button
        onClick={() => onClick(session.sessionId)}
        className="mt-6 w-full btn btn-primary"
      >
        <FaDownload className="mr-2" /> Anzeigen
      </button>
    </div>
  </div>
);

const ChatLogsList = () => {
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [currentSessions, setCurrentSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const sessionsPerPage = 9;
  const navigate = useNavigate();

  useEffect(() => {
    const loadSessions = async () => {
      try {
        const data = await fetchAllChatSessions();
        const sessionsWithNames = await resolveUsernames(data);
        setSessions(sessionsWithNames);
        setFilteredSessions(sessionsWithNames);
        const sorted = sessionsWithNames.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCurrentSessions(sorted.slice(0, sessionsPerPage));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadSessions();
  }, []);

  const handleSort = (order) => {
    setSortOrder(order);
    const sorted = [...filteredSessions].sort((a, b) =>
      order === "asc"
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt)
    );
    setFilteredSessions(sorted);
    setCurrentPage(0);
    setCurrentSessions(sorted.slice(0, sessionsPerPage));
  };

  const handleSessionClick = (sessionId) => {
    navigate(`/chatlog?id=${sessionId}`);
  };

  const handleExportCSV = () => {
    const headers = [
      { label: "Session ID", key: "sessionId" },
      { label: "Benutzername", key: "accusedName" },
      { label: "Benutzer UUID", key: "accusedUuid" },
      { label: "Erstellt am", key: "createdAt" },
    ];
    const csvContent = [
      headers.map((header) => header.label).join(","),
      ...filteredSessions.map((session) =>
        [
          session.sessionId,
          session.accusedName,
          session.accusedUuid,
          new Date(session.createdAt).toLocaleString(),
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `ChatLogs_${new Date().toISOString()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSearch = useMemo(
    () =>
      debounce((term) => {
        const lowerTerm = term.toLowerCase();
        const filtered = sessions.filter(
          (session) =>
            (session.accusedName &&
              session.accusedName.toLowerCase().includes(lowerTerm)) ||
            session.sessionId.toLowerCase().includes(lowerTerm)
        );
        setFilteredSessions(filtered);
        handleSort(sortOrder);
        setCurrentPage(0);
        setCurrentSessions(filtered.slice(0, sessionsPerPage));
      }, 300),
    [sessions, sortOrder]
  );

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch(e.target.value);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    const offset = selectedPage * sessionsPerPage;
    setCurrentSessions(filteredSessions.slice(offset, offset + sessionsPerPage));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-base-200 text-gray-700">
        <p className="text-xl animate-pulse">Lade Chatlogs...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-base-200 text-red-500">
        <p className="text-xl">Fehler beim Laden der Chatlogs: {error}</p>
      </div>
    );
  }

  return (
    <>
      {/* Hintergrundbild mit Unschärfe und Verdunkelung */}
      <div
        className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-md brightness-50 z-[-2]"
        style={{
          backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')`,
        }}
      ></div>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-60 z-[-1]"></div>

      {/* Hauptinhalt */}
      <div className="flex flex-col items-center justify-start min-h-screen pt-24 px-4 py-8">
        <div className="bg-base-200 bg-opacity-90 rounded-xl shadow-2xl w-full max-w-7xl flex flex-col h-full md:p-8 p-4 animate-fade-in-up">
          {/* Überschrift */}
          <h1 className="text-4xl md:text-5xl font-extrabold text-center mb-6 text-primary">
            Chatlogs
          </h1>

          {/* Such- und Sortierleiste */}
          <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6 space-y-4 md:space-y-0 md:space-x-6">
            {/* Suchleiste */}
            <div className="relative w-full md:w-2/3 lg:w-1/2">
              <input
                type="text"
                value={searchTerm}
                onChange={onSearchChange}
                placeholder="Nach Benutzer oder Session ID suchen..."
                className="input input-bordered w-full pl-12"
              />
              <FaSearch className="w-5 h-5 text-gray-500 absolute left-4 top-1/2 transform -translate-y-1/2" />
            </div>

            {/* Sortieroptionen und Export */}
            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto">
              <div className="flex items-center space-x-2">
                <span className="text-lg font-medium text-gray-600">
                  Sortieren:
                </span>
                <button
                  onClick={() => handleSort("asc")}
                  className={`btn ${
                    sortOrder === "asc" ? "btn-primary" : "btn-outline"
                  } btn-sm`}
                >
                  <FaSortAmountUp className="mr-1" /> Aufsteigend
                </button>
                <button
                  onClick={() => handleSort("desc")}
                  className={`btn ${
                    sortOrder === "desc" ? "btn-primary" : "btn-outline"
                  } btn-sm`}
                >
                  <FaSortAmountDown className="mr-1" /> Absteigend
                </button>
              </div>
              <button
                onClick={handleExportCSV}
                className="btn btn-secondary btn-sm"
              >
                <FaDownload className="mr-2" /> Exportieren
              </button>
            </div>
          </div>

          {/* Sessions als Karten */}
          {currentSessions.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {currentSessions.map((session) => (
                <ChatCard
                  key={session.sessionId}
                  session={session}
                  onClick={handleSessionClick}
                />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500">
              <p className="text-xl">Keine Chatlogs gefunden.</p>
            </div>
          )}

          {/* Paginierung */}
          {filteredSessions.length > sessionsPerPage && (
            <div className="mt-12 flex justify-center">
              <ReactPaginate
                previousLabel={"← Zurück"}
                nextLabel={"Weiter →"}
                breakLabel={"..."}
                pageCount={Math.ceil(filteredSessions.length / sessionsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"flex space-x-2"}
                pageClassName={"btn btn-outline btn-sm"}
                pageLinkClassName={"focus:outline-none"}
                previousClassName={"btn btn-outline btn-sm"}
                nextClassName={"btn btn-outline btn-sm"}
                activeClassName={"btn-primary text-white"}
                disabledClassName={"btn-disabled"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatLogsList;
