// src/components/Login.js

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../api";
import LoginForm from "./LoginForm";
import { AuthContext } from '../context/AuthContext';

const Login = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleLogin = async (username, password) => {
    setError(null);
    try {
      const response = await loginUser(username, password);

      // Aktualisiere den Auth Context
      login();

      // Überprüfe die erforderliche Aktion und navigiere entsprechend
      if (response.data.action === "change_password") {
        navigate("/change-password");
      } else if (response.data.action === "setup_2fa") {
        navigate("/setup-2fa");
      } else if (response.data.action === "dashboard") {
        navigate("/dashboard");
      } else {
        setError("Unerwartete Antwort vom Server");
      }
    } catch (err) {
      setError(err.message || "Login fehlgeschlagen");
    }
  };

  return <LoginForm onLogin={handleLogin} error={error} />;
};

export default Login;
