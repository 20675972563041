// src/components/ErrorPage.js
import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

const ErrorPage = ({ message, onRetry }) => {
    return (
        <>
            {/* Hintergrundbild mit Unschärfe und Verdunkelung */}
            <div
                className="fixed top-0 left-0 w-full h-full bg-cover bg-center filter blur-sm brightness-50 z-[-2] transition-all duration-500"
                style={{ backgroundImage: `url('https://wallpapercave.com/wp/vUYioU8.jpg')` }}
            ></div>
            <div className="fixed top-0 left-0 w-full h-full bg-black opacity-40 z-[-1]"></div>

            {/* Zentraler Container */}
            <div className="flex flex-col items-center justify-center min-h-screen px-4 py-8">
                <div className="bg-gray-900 bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-2xl p-8 w-11/12 max-w-2xl text-center">
                    {/* Minecraft Icon */}

                    {/* Fehler-Icon */}
                    <ExclamationCircleIcon className="w-16 h-16 text-red-400 mx-auto mb-4" />

                    {/* Fehler-Titel */}
                    <h1 className="text-4xl font-bold text-white mb-4">Fehler aufgetreten</h1>

                    {/* Fehlermeldung */}
                    <p className="text-gray-300 text-lg mb-6">
                        {message || "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."}
                    </p>

                    {/* Erneut versuchen Button */}
                    {onRetry && (
                        <button
                            className="px-6 py-3 rounded-lg bg-red-500 text-white font-medium text-lg shadow-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2 transition-all"
                            onClick={onRetry}
                        >
                            Erneut versuchen
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default ErrorPage;
